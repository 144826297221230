import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { delay, map } from 'rxjs/operators';

import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(private http: HttpClient, @Inject('LOCALSTORAGE') private localStorage: Storage) { }

    login(email: string, password: string) {
        return this.http.post<any>("api/v1/auth/admin-login", { email, password })
            .pipe(
                map((response) => {
                    console.log("🚀 ~ AuthenticationService ~ map ~ response:", response)
                    const user = {
                        ...response?.data,
                        token: response?.tokenInfo?.token,
                        isAdmin: true,
                        expiration: moment().add(1, 'days').toDate(),
                        fullName: response?.data?.first_name + ' ' + response?.data?.last_name
                    }
                    this.localStorage.setItem('currentUser', JSON.stringify(user));
                    return true;
                }));
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.localStorage.removeItem('currentUser');
    }

    getCurrentUser(): any {
        // TODO: Enable after implementation
        return JSON.parse(this.localStorage.getItem('currentUser') || '{}');
        // return {
        //     token: 'aisdnaksjdn,axmnczm',
        //     isAdmin: true,
        //     email: 'john.doe@gmail.com',
        //     id: '12312323232',
        //     alias: 'john.doe@gmail.com'.split('@')[0],
        //     expiration: moment().add(1, 'days').toDate(),
        //     fullName: 'John Doe'
        // };
    }

    passwordResetRequest(email: string) {
        return of(true).pipe(delay(1000));
    }

    changePassword(old_password: string, password: string, confirm_password: string) {
        return this.http.put<any>("api/v1/auth/change-password", { old_password, password, confirm_password }).pipe();
    }

    passwordReset(email: string, token: string, password: string, confirmPassword: string): any {
        return of(true).pipe(delay(1000));
    }
}
