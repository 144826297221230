import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemNamespaceService } from 'src/app/core/services/system-namespace.service';

@Component({
  selector: 'app-system-namespace-select',
  templateUrl: './system-namespace-select.component.html',
  styleUrls: ['./system-namespace-select.component.css']
})
export class SystemNamespaceSelectComponent {
  @Input() 
  public onChangeSystemNamespace?: Function;

  systemNamespaceList: any[] = [];

  constructor(private systemNamespaceService: SystemNamespaceService,) {
  }

  ngOnInit() {
    this.getSystemNamespaceList();
  }

  getSystemNamespaceList() {
    this.systemNamespaceService.getSystemNamespaceList().subscribe((res: any) => {
      console.log("🚀 ~ ManagerListComponent ~ getSystemNamespaceList ~ res:", res)
      this.systemNamespaceList = res?.data || [];
      this.onChangeItem({ target: { selectedIndex: 0 } });
    })
  }

  onChangeItem(event?: any) {
    this.onChangeSystemNamespace?.(this.systemNamespaceList?.[event?.target?.selectedIndex || 0])
  }
}

export class SystemNamespaceSelectModel {
  constructor(public onChangeSystemNamespace: (item: any) => void) {
  }
}
