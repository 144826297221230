import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SystemNamespaceService {
    constructor(private http: HttpClient) { }

    getSystemNamespaceList(page: number = 1, limit: number = 1000000): Observable<any> {
        let params: any = { page, limit };
        console.log("🚀 ~ SystemNamespaceService ~ getSystemNamespaceList ~ params:", params)
        return this.http.get<any>(`api/v1/system-namespace/system-namespace-list`, { params }).pipe()
    }

    createSystemNamespace(body: any): Observable<any> {
        console.log("🚀 ~ SystemNamespaceService ~ addSystemNamespace ~ body:", body)
        return this.http.post<any>(`api/v1/system-namespace/create-system-namespace`, body).pipe()
    }

    updateSystemNamespace(body: any): Observable<any> {
        console.log("🚀 ~ SystemNamespaceService ~ updateSystemNamespace ~ body:", body)
        return this.http.put<any>(`api/v1/system-namespace/update-system-namespace`, body).pipe()
    }

    deleteSystemNamespaces(ids: number[]): Observable<any> {
        console.log("🚀 ~ SystemNamespaceService ~ addSystemNamespace ~ body:", { ids })
        return this.http.delete<any>(`api/v1/system-namespace/delete-system-namespaces`, { body: { ids } }).pipe()
    }
}
