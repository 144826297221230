<h1 mat-dialog-title style="text-align: center;">
  {{title}}
</h1>

<div mat-dialog-content style="text-align: center;">
  <p>{{message}}</p>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onDismiss()" style="flex: 1;">Huỷ</button>
  <button mat-raised-button color="primary" (click)="onConfirm()" style="flex: 1;">Đồng ý</button>
</div>